import {
  widgetService
} from '../../../../server'
import {
  chartContext,
  dataContext,
  chartManager
} from '../../../../charts'

export default function widgetShareCtrl($scope, $stateParams,ModalUtils,$state) {
  $scope.loadingPre = true;

  //事件监听
  $scope.$on('configChange', function (event, selectItems) {
    //切片器的值已经更新，将filter 应用到图表里面
    $scope.preview()
  });


  widgetService.getWidgetDetail({
    id: $stateParams.widgetId
  }).then(function (response) {
    if (!response.dataset || !response.widget) {
      ModalUtils.alert("图表已被删除或未被赋予权限!", "modal-warning", "sm");
      return;
    }
    $scope.dataset = response.dataset;
    $scope.widget = response.widget;
    $scope.dataset.data = angular.fromJson(response.dataset.data);
    $scope.widget.data = angular.fromJson(response.widget.data);
    $scope.loadingPre = false;
    // console.log("widget", $scope.widget)
    // $scope.editWgt($scope.widget);
    $scope.showWidget($scope.widget);
  })

  //点击展示图表

  $scope.showWidget = function (widget) {
    $scope.curWidgetName = widget.name;
    $('#showWidgetId').html("");
    $scope.loadingPre = true;
    $('#showWidgetId').html("<div id='show_preview' style='min-height: 450px; user-select: text;'></div>");
    var charType = widget.data.config.chart_type;
    //百度地图特殊处理
    if (charType == 'chinaMapBmap') {
      var chartRender = chartContext.render($('#show_preview'), {
        config: widget.data.config,
        datasource: $scope.datasource ? $scope.datasource.id : null,
        query: widget.data.query,
        dtpId: $scope.dtp ? $scope.dtp.id : null,
        isDtpManager: $scope.dtp ? $scope.dtp.isDtpManager : false,
        datasetId: $scope.customDs ? undefined : widget.data.datasetId
      });
      $scope.loadingPre = false;
    } else {
      var chartRender = chartContext.render($('#show_preview'), {
        config: widget.data.config,
        datasource: $scope.datasource ? $scope.datasource.id : null,
        query: widget.data.query,
        dtpId: $scope.dtp ? $scope.dtp.id : null,
        isDtpManager: $scope.dtp ? $scope.dtp.isDtpManager : false,
        datasetId: $scope.customDs ? undefined : widget.data.datasetId
      }, function (option) {
        switch ($scope.curWidget.config.chart_type) {
          case 'bugdget':
            $scope.previewDivWidth = 12;
            break;
          case 'line':
            $scope.previewDivWidth = 12;
            // option.toolbox = {
            //   feature: {
            //     dataView: {
            //       show: true,
            //       readOnly: true
            //     }
            //   }
            // };
            break;
          case 'pie':
            $scope.previewDivWidth = 12;
            // option.toolbox = {
            //   feature: {
            //     dataView: {
            //       show: true,
            //       readOnly: true
            //     }
            //   }
            // };
            break;
          case 'kpi':
            $scope.previewDivWidth = 6;
            break;
          case 'kpi2':
            $scope.previewDivWidth = 6;
            break;
          case 'table':
            $scope.previewDivWidth = 12;
            break;
          case 'funnel':
            $scope.previewDivWidth = 12;
            // option.toolbox = {
            //   feature: {
            //     dataView: {
            //       show: true,
            //       readOnly: true
            //     }
            //   }
            // };
            break;
          case 'sankey':
            $scope.previewDivWidth = 12;
            // option.toolbox = {
            //   feature: {
            //     dataView: {
            //       show: true,
            //       readOnly: true
            //     }
            //   }
            // };
            break;
          case 'map':
            $scope.previewDivWidth = 12;
            break;
          case 'areaMap':
            $scope.previewDivWidth = 12;
            break;
          case 'chinaMap':
            $scope.previewDivWidth = 12;
            break;
          case 'relation':
            $scope.previewDivWidth = 12;
            break;
        }
      }, null, !$scope.loadFromCache)
      $scope.loadingPre = false;
    }
    // chartRender.then(data => {
    //   $scope.loadingPre = false;
    //   if (!data) {
    //     ModalUtils.alert("图表已被删除或未被赋予权限!", "modal-warning", "sm");
    //     return;
    //   }
    // });
  };
  $scope.backtoBoardList = function(){
     $state.go('boardList')
  }

}
