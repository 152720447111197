import { dataService } from '../../../../server'
import {util} from '../../../utils/util'
import {boardContext} from '../../../../charts'
var moment = require('moment');
export default function paramCtrl( $scope, $timeout, ngScreening, ParamFilterModal) {
    $scope.randomClass = Math.random().toString(36).substr(2);
    var evalValue = function (value) {
        if (isNaN(Number(value))) {
            var now = function (i, key) {
                if (i == undefined) {
                    return +moment();
                }
                return +moment().add(i, key);
            };
            var interval = function (i, key) {
                if (i == undefined) {
                    i == 1
                }
                var y = 0;
                switch (key) {
                    case 'h':
                        y = 60 * 60 * 1000;
                        break;
                    case 'd':
                        y = 24 * 60 * 60 * 1000;
                        break;
                    case 'm':
                        y = 60 * 1000;
                        break;
                    case 's':
                        y = 1000;
                        break;
                }
                return i * y;
            };
            return eval(value);
        } else {
            return value;
        }
    };

    // param生成title
    var updateParamTitle = function (param) {
        if ('slider' == param.paramType) {
          return;
        }
    
        if (param.values && param.values.length > 0) {
    
          //判断是否需要隐藏当前过滤词条
          var flag = false;     //默认不隐藏
    
          var promisecontain = [];
          for(var i = 0; i < param.values.length; i++) {
            //判断存在不是时间的表达式---存在即终止循环
            if(param.values[i].indexOf('{') == 0 && param.values[i].indexOf('{now') !== 0) {
              flag = true;
              break;
            }
            var newPromise = new Promise((resolve, reject) => {
              if(param.values[i].indexOf('{now') == 0) {
                dataService.getTimeParserExpression(param.values[i]).then(res => {
                  resolve(res);
                });
              } else {
                resolve(param.values[i]);
              }
            });
            promisecontain.push(newPromise);
    
          }
          if(!flag){
            Promise.all(promisecontain).then(res=>{
              var paramObj = util.updateParamTitle(param, res);
              param.title = paramObj;
            });
          } else {
            param.title = undefined;
          }
    
        } else {
          //values为空
          param.title = undefined;
        }
    
    };

    $scope.selectedItems = [];
    var formatter = function (d, f) {
        if (_.isUndefined(f)) {
            return d;
        } else {
            return moment(d).format(f);
        }
    };

    $scope.init = function () {
        $scope.param = $scope.$parent.param;
        //初始化已选项
        _.each($scope.param.values, (value) => { $scope.selectedItems.push({ name: value }) })
        $scope.param.selects = [];
        if (!$scope.param.type) $scope.param.type = '=';
        if (!$scope.param.values) $scope.param.values = [];
        if ($scope.param.paramType == 'slider') {
            var cfg = $scope.param.cfg;
            var _max = evalValue(_.result(cfg, 'max', null));
            var _min = evalValue(_.result(cfg, 'min', null));
            // var apply = _.debounce($scope.$parent.applyParamFilter, 800);
            var apply = _.debounce(function () {
                // PubSub.publish('slicerChange', '');
                $scope.resetFilter(true);
            }, 800)

            $scope.slider = {
                minValue: _max - Number(evalValue(_.result(cfg, 'range', 0))),
                maxValue: _max,
                options: {
                    floor: _min,
                    ceil: _max,
                    draggableRange: true,
                    enforceStep: false,
                    maxRange: Number(evalValue(_.result(cfg, 'maxRange', null))),
                    step: evalValue(_.result(cfg, 'step', 1 * 60 * 1000)),
                    translate: function (value) {
                        return formatter(value, cfg.formatter);
                    },
                    onChange: function (sliderId, modelValue, highValue, pointerType) {
                        $scope.param.type = _.result(cfg, 'filterType', '[a,b]');
                        $scope.param.values = [formatter(modelValue, cfg.value_fmt), formatter(highValue, cfg.value_fmt)];
                        updateParamTitle($scope.param)
                        apply();
                        // console.log("=======++++++")
                        // console.log($scope.board)

                    }
                }
            };
            $scope.param.type = _.result(cfg, 'filterType', '[a,b]');
            $scope.param.values = [formatter($scope.slider.minValue, cfg.value_fmt), formatter($scope.slider.maxValue, cfg.value_fmt)];
            $scope.param.refresh = function () {
                if ($scope.slider.maxValue == $scope.slider.options.ceil) {
                    var _range = $scope.slider.maxValue - $scope.slider.minValue;
                    var cfg = $scope.param.cfg;
                    var max = evalValue(_.result(cfg, 'max', null));
                    var min = evalValue(_.result(cfg, 'min', null));
                    $scope.slider.maxValue = max;
                    $scope.slider.minValue = max - _range;
                    $scope.slider.options.floor = min;
                    $scope.slider.options.ceil = max;
                    $scope.param.type = _.result(cfg, 'filterType', '[a,b]');
                    $scope.param.values = [formatter($scope.slider.minValue, cfg.value_fmt), formatter($scope.slider.maxValue, cfg.value_fmt)];
                }
            }
        }else if($scope.param.paramType == 'datetime'){
            let cfg = $scope.param.cfg;
            let formatType=_.result(cfg, 'formatterType', 'day')
            let dateformat= cfg.formatter?cfg.formatter:"YYYY-MM-DD";
            let timePicker=false
            if(formatType=='time'){
                dateformat='YYYY-MM-DD HH:mm:ss'
                timePicker=true;
            }
            //初始化将非自定义的时间进行调整
            if($scope.param.values.length==3){
                switch ($scope.param.values[2]) {
                    case '今天' :
                        $scope.param.values[0] = util.getCurrDays()[0].format(dateformat);
                        $scope.param.values[1] = util.getCurrDays()[1].format(dateformat);
                        break;
                    case '昨天' :
                        $scope.param.values[0] = util.getYesterday()[0].format(dateformat);
                        $scope.param.values[1] = util.getYesterday()[1].format(dateformat);
                        break;
                    case '本周' :
                        $scope.param.values[0] = util.getCurrWeekDays()[0].format(dateformat);
                        $scope.param.values[1] = util.getCurrWeekDays()[1].format(dateformat); 
                        break;
                    case '上月' :
                        $scope.param.values[0] = moment(util.getLastMonthDays()[0]).format(dateformat);
                        $scope.param.values[1] = util.getLastMonthDays()[1].format(dateformat);
                        break;
                    case '本月' :
                        $scope.param.values[0] = moment(util.getCurrMonthDays()[0]).format(dateformat);
                        $scope.param.values[1] = util.getCurrMonthDays()[1].format(dateformat);
                        break;
                    case '今年' :
                        $scope.param.values[0] = util.getCurrYers()[0].format(dateformat);
                        $scope.param.values[1] = util.getCurrYers()[1].format(dateformat); 
                        break;
                    case '永久' :
                        $scope.param.values[1] = moment().format(dateformat); 
                        break;
                }
            }
           
             // options="optsCustom"
             $scope.dateRangeOption={
                 dateLimit : {
                     days : 365*1000
                 }, //起止时间的最大间隔
                 showDropdowns : true,
                 showWeekNumbers : false, //是否显示第几周
                 timePicker : timePicker, //是否显示小时和分钟
                 timePickerIncrement : 1, //时间的增量，单位为分钟
                 timePicker24Hour: true,
                //  alwaysShowCalendars: true,
                 ranges : {
                     '今天': util.getCurrDays( ),
                     '昨天': util.getYesterday( ),
                     '本周': util.getCurrWeekDays( ),
                     '上月': util.getLastMonthDays(),
                     '本月': util.getCurrMonthDays( ),
                     '今年': util.getCurrYers( ),
                     '永久': ['1970-01-01',moment() ]
                 },
                 opens : 'right', //日期选择框的弹出位置
                 buttonClasses : [ 'btn btn-default' ],
                 applyClass : 'btn-small btn-primary blue',
                 cancelClass : 'btn-small',
                 format :dateformat, //控件中from和to 显示的日期格式
                 separator : ' to ',
                 locale : {
                     applyLabel : '确定',
                     cancelLabel : '清空',
                     fromLabel : '起始时间',
                     toLabel : '结束时间',
                     customRangeLabel : '自定义',
                     daysOfWeek : [ '日', '一', '二', '三', '四', '五', '六' ],
                     monthNames : [ '一月', '二月', '三月', '四月', '五月', '六月',
                                    '七月', '八月', '九月', '十月', '十一月', '十二月' ],
                                    firstDay : 1
                 },   //汉化日期控件
                 eventHandlers: {
                    'cancel.daterangepicker': function(event, picker) {
                        $scope.datetime.rangeDate = {
                            startDate: '',
                              endDate: ''
                        };
                        $scope.param.values = [];
                        $scope.resetFilter(true);   //下面的$watch--上面的情况不触发
                    }
                }
             }
             $scope.datetime={};
             $scope.datetime.rangeDate = {
                startDate: '',
                  endDate: ''
            };
             //处理默认值
             if($scope.param.values.length==2 || $scope.param.values.length==3){
                 try {
                    $scope.datetime.rangeDate = {
                        startDate:formatter($scope.param.values[0],dateformat) ,
                         endDate:formatter($scope.param.values[1],dateformat)}
                 } catch (error) {
                    $scope.datetime.rangeDate = {
                        startDate:'',
                         endDate:''}
                 }
               ;  
             }

            //触屏添加拖动的touch方法-----不使用定时器添加不了，可能此时页面对应元素未初始化完
            $timeout(function(){
                //判断是否支持touch方法
                if(window.TouchEvent) {
                    $(`#${$scope.randomClass}`).on('show.daterangepicker', function(event, picker){
                        //每次打开的时候都清下touchstart的事件
                        picker.container.off("touchstart");

                        picker.container.on("touchstart", function(e){
                            if(e.originalEvent.targetTouches.length > 1) {
                                return
                            }
                            
                            var wWidth = $('body').width();
                            var oldTop = parseFloat(picker.container.css("top"));

    
                            var oldLeft = picker.container.css("left");
                            oldLeft ==='auto' ? null : oldLeft = parseFloat(oldLeft);
                            var oldRight = picker.container.css("right");
                            oldRight ==='auto' ? null : oldRight = parseFloat(oldRight);
    
                            var oldX = e.originalEvent.targetTouches[0].pageX;
                            var oldY = e.originalEvent.targetTouches[0].pageY;
    
                            picker.container.on("touchmove", function(e){
                                var newX = e.originalEvent.targetTouches[0].pageX - oldX;
                                var newY = e.originalEvent.targetTouches[0].pageY - oldY;
                                picker.container.css('top', oldTop + newY);

                                if(oldLeft ==='auto') {
                                    oldRight - newX <= 0 ? picker.container.css('right', 0) : picker.container.css('right', oldRight - newX);
                                } else {
                                    var newLeft = oldLeft + newX;
                                    if(newLeft <= 0){
                                        picker.container.css('left', 0);
                                    } else {
                                        var maxLeft = wWidth - picker.container.width();
                                        oldLeft + newX >= maxLeft ? picker.container.css('left', maxLeft) : picker.container.css('left', oldLeft + newX);
                                    }
                                }
                            });
    
                            picker.container.on("touchend", function(){
                                picker.container.off("touchmove");
                                picker.container.off("touchend");
                            });

                        });
                    });
                }
            }, 1000);

             
             $scope.$watch('datetime.rangeDate', function(newDate, oldDate) {
                 if(newDate==oldDate){
                     return;
                 }
                //  $scope.param.type = _.result(cfg, 'filterType', '[a,b]');
                // try {
                //    $scope.param.values = [newDate.startDate.format(dateformat)  ,newDate.endDate.format(dateformat)];
                // } catch (error) {
                //     $scope.param.values=[]
                // } 
                //  util.updateParamTitle($scope.param) 
                //  $scope.resetFilter(true);
                //  $scope.$apply()
                
                //通过daterangepicker库的自定义事件获取chosenLabel----angular组件的options事件方法获取不到
                $(`#${$scope.randomClass}`).on('apply.daterangepicker', function(event, picker){
                    var curType;        //当前选择的时间类型
                    if(picker.chosenLabel == '自定义') {
                        curType = "";
                    } else {
                        var curType = picker.chosenLabel; 
                    }

                    $scope.param.type = _.result(cfg, 'filterType', '[a,b]');
                    try {
                       $scope.param.values = [newDate.startDate.format(dateformat)  ,newDate.endDate.format(dateformat)];
                    } catch (error) {
                        $scope.param.values=[]
                    } 
                    if(curType && $scope.param.values.length == 2){
                        $scope.param.values.push(curType);
                    }
                    updateParamTitle($scope.param);
                    $scope.resetFilter(true);
                    $scope.$apply();
                    $(`#${$scope.randomClass}`).off('apply.daterangepicker');   //执行完毕解绑自定义事件
                });
             }, false);
              
        } else {
            _.each($scope.param.col, function (c) {
                var p;
                if (_.isUndefined(c.datasetId)) {
                    _.each($scope.board.layout.rows, function (row) {
                        _.each(row.widgets, function (widget) {
                            if (widget.widget.id == c.widgetId) {
                                p = {
                                    datasourceId: widget.widget.data.datasource,
                                    query: angular.toJson(widget.widget.data.query),
                                    datasetId: null
                                };
                            }
                        });
                    });
                } else {
                    p = { datasourceId: null, query: null, datasetId: c.datasetId };
                }
                var params = {
                    datasourceId: p.datasourceId,
                    query: p.query,
                    datasetId: p.datasetId,
                    colmunName: c.column
                }
                var boardCache = boardContext.getBoardCache();
                var boardId = boardContext.getBoardId();
                var cache = null;
                if(boardCache && boardCache.dimensionCache && boardCache.dimensionCache.isCache) {
                    cache = {
                        type : boardCache.dimensionCache.type + boardId,
                        isCache : boardCache.dimensionCache.isCache,
                        expireTime : boardCache.dimensionCache.expireTime
                    };
                }
                dataService.getDimensionValues(params, cache).then(function(response){
                    initDimensionValues(response);
                });

            });
        }
        // $scope.$emit('paramInitFinish', $scope.param);
        $scope.resetFilter();  //在编辑看板更换paramType时能即时刷新
    };
    
    //init的else条件获取维度值
    function initDimensionValues(data){
        _.each(data, function (s) {
            if (_.indexOf($scope.param.selects, s) < 0) {
                $scope.param.selects.push(s);
            }
        });
        $scope.select = [];
        _.each($scope.param.selects, function (item) {
            var temObj = {};
            temObj.name = item;
            if (_.filter($scope.selectedItems, (seleced) => {
                return seleced.name == item;
            }).length > 0) {
                temObj.isChecked = true;
            }
            $scope.select.push(temObj);
            // console.log(temObj);
        })
        $scope.select.sort(function (a, b) {
            //同时为非数字时排序
            if(isNaN(a.name) && isNaN(b.name)){
                //循环比较字符--第一个开始
                for(var i= 0; i < a.name.length; i++){
                    if(a.name[i] == b.name[i]){
                        continue;
                    }
                    //同时为非数字或同为数字
                    if( (isNaN(a.name[i]) && isNaN(b.name[i]) ) || (!isNaN(a.name[i]) && !isNaN(b.name[i])) ) {
                        return a.name[i] < b.name[i] ? -1 : 1;
                    } else if( isNaN(a.name[i]) && !isNaN(b.name[i]) ){
                        return 1;
                    } else if( !isNaN(a.name[i]) && isNaN(b.name[i]) ){
                        return -1;
                    }

                }
                return 0;
            } else if(isNaN(a.name) && !isNaN(b.name)) {
                return 1;
            //非数字在数字后面
            } else if(!isNaN(a.name) && isNaN(b.name)) {
                return -1;
            } else {
                return a.name - b.name; //数字大的在后面
            }
        });
        $scope.$apply();
    };

    if ($scope.param.paramType == 'datetime') { 
        
        $scope.$emit('paramInitFinish', $scope.param);

 
    }

    // 下拉选择的初始化
    $scope.initPull = function(){
        $scope.param = $scope.$parent.param;
        $scope.param.selects = [];
        if (!$scope.param.type) $scope.param.type = '=';
        if (!$scope.param.values) $scope.param.values = [];
        // 单选最多一个值
        if($scope.param.pulltype == 'radio' && $scope.param.values.length > 1 ) {
            $scope.param.values.length = 1;
        }

        var promiseContain = [];
        for (let j = 0; j < $scope.param.col.length; j++) {
            var p,
                c = $scope.param.col[j];
            if (_.isUndefined(c.datasetId)) {
                _.each($scope.board.layout.rows, function (row) {
                    _.each(row.widgets, function (widget) {
                        if (widget.widget.id == c.widgetId) {
                            p = {
                                datasourceId: widget.widget.data.datasource,
                                query: angular.toJson(widget.widget.data.query),
                                datasetId: null
                            };
                        }
                    });
                });
            } else {
                p = { datasourceId: null, query: null, datasetId: c.datasetId };
            }

            var newpromise = new Promise((resolve, reject) => {
                // $http.post("api/bcp/dashboard/getDimensionValues", {
                //     datasourceId: p.datasourceId,
                //     query: p.query,
                //     datasetId: p.datasetId,
                //     colmunName: c.column
                // })

                var boardCache = boardContext.getBoardCache();
                var boardId = boardContext.getBoardId();
                var cache = null;
                if(boardCache && boardCache.dimensionCache && boardCache.dimensionCache.isCache) {
                    cache = {
                        type : boardCache.dimensionCache.type + boardId,
                        isCache : boardCache.dimensionCache.isCache,
                        expireTime : boardCache.dimensionCache.expireTime
                    };
                }
                dataService.getDimensionValues({
                        datasourceId: p.datasourceId,
                        query: p.query,
                        datasetId: p.datasetId,
                        colmunName: c.column
                }, cache).then(function (response) {
                        resolve(response);
                        $(`#${$scope.randomClass}`).selectpicker({
                            noneSelectedText: '请选择筛选条件'
                        });
                });

            })
            promiseContain.push(newpromise);
        }
        Promise.all(promiseContain).then(res => {
            _.each(res, response => {
                _.each(response, function (s) {
                    let value = s.toString();
                    if (_.indexOf($scope.param.selects, value) < 0) {
                        $scope.param.selects.push(value);
                    }
                });
            })
            $scope.param.selects.sort(function (a, b) {
                //同时为非数字时排序
                if(isNaN(a) && isNaN(b)){
                    //循环比较字符--第一个开始
                    for(var i= 0; i < a.length; i++){
                        if(a[i] == b[i]){
                            continue;
                        }
                        //同时为非数字或同为数字
                        if( (isNaN(a[i]) && isNaN(b[i]) ) || (!isNaN(a[i]) && !isNaN(b[i])) ) {
                            return a[i] < b[i] ? -1 : 1;
                        } else if( isNaN(a[i]) && !isNaN(b[i]) ){
                            return 1;
                        } else if( !isNaN(a[i]) && isNaN(b[i]) ){
                            return -1;
                        }

                    }
                    return 0;
                } else if(isNaN(a) && !isNaN(b)) {
                    return 1;
                //非数字在数字后面
                } else if(!isNaN(a) && isNaN(b)) {
                    return -1;
                } else {
                    return a - b; //数字大的在后面
                }
            });
            $(`#${$scope.randomClass}`).empty();
            // 单选的情况增加一个值为''的选项，作为清空
            if($scope.param.pulltype == 'radio') {
                $(`#${$scope.randomClass}`).append(`<option value=''>请选择筛选条件</option>`)
            }
            for (let k = 0; k < $scope.param.selects.length; k++) {
                $(`#${$scope.randomClass}`).append(`<option value=${$scope.param.selects[k]}>${$scope.param.selects[k]}</option>`)
            }
            let deflaultValues = $scope.param.values;
            // 单选是一个值
            if($scope.param.pulltype == 'radio') {
                deflaultValues = $scope.param.values[0] === undefined ? '' : $scope.param.values[0];
            }
            $(`#${$scope.randomClass}`).selectpicker('val', deflaultValues); //展示默认值
            $(`#${$scope.randomClass}`).selectpicker('refresh');
            $(`#${$scope.randomClass}`).selectpicker('render');
            $(`#${$scope.randomClass}`).on('changed.bs.select',function(e, clickedIndex, isSelected, previousValue){
                let pValues = $(this).val();
                // 前者的''是单选的补充清空，后者是多选的清空
                if(pValues === '' || pValues == null) {
                    $scope.param.values = [];
                } else {
                    if($scope.param.pulltype == 'radio') {
                        $scope.param.values = [pValues];
                    } else {
                        $scope.param.values = pValues;
                    }
                }

                // 恢复取值两端存在空格的值
                if($scope.param.values.length > 0) {
                    let flag = 0, flagL = $scope.param.values.length;
                    _.each($scope.param.selects, (s, i)=>{
                        let index = $scope.param.values.indexOf(s.trim());
                        if(index > -1) {
                            $scope.param.values[index] = s;
                            flag++;
                            if(flag === flagL) {
                                return false;
                            }
                        }
                    });
                }
                updateParamTitle($scope.param)
                // console.log($(this).val())
                // PubSub.publish('slicerChange', $scope.param.values); 
                $scope.resetFilter(true);
            })
        })
        $scope.$emit('paramInitFinish', $scope.param);
    };

    if ($scope.param.paramType == 'pull' || $scope.param.paramType=='combobox') { 
        // $scope.initPull();
    }

    // 平铺使用的方法
    $scope.selectedCallback = function () {
        let selectItems = []
        _.each(ngScreening.getChecked($scope.select), function (item) {
            selectItems.push(item.name);
        })
        $scope.param.values = selectItems;

        //考虑过滤器未加载完，而开始操作的情况，给限定init使用的$scope.selectedItems重新赋值
        $scope.selectedItems = [];
        _.each($scope.param.values, (value) => { $scope.selectedItems.push({ name: value }) });
        updateParamTitle($scope.param)
        // console.log('select'+JSON.stringify(selectItems))
        $scope.resetFilter(true);
        // PubSub.publish('slicerChange', selectItems); //向其他控制器广播$scope.versionid值的变化。
        // console.log("slicerChange send message")
        // $scope.applyParamFilter();
    }

    //被注释使用的方法 
    $scope.selectedPullCallback = function (selected) {
        let selectItems = []
        _.each(selected, function (item) {
            selectItems.push(item.name);
        })
        $scope.param.values = selectItems;
        updateParamTitle($scope.param)
        $scope.resetFilter(true);
        // PubSub.publish('slicerChange', selectItems); //向其他控制器广播$scope.versionid值的变化。
        // console.log("slicerChange send message")
        // $scope.applyParamFilter();
    }

    // 弹窗使用的方法
    $scope.selectParamValue = function (param) {
        // param = angular.copy(param);
        const ok = function (p) {
            $scope.param.values = p.values;
            $scope.param.type = p.type;
            updateParamTitle($scope.param)
            // PubSub.publish('slicerChange', p.values);
            $scope.resetFilter(true);
        };
        ParamFilterModal.openSelector(param, ok);
    };

} 
