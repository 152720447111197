export default function widgetTypeFilter() {
  return function (widgetList, search) {
    if ((!search||search == "")&& search!=0) return widgetList;
    const widgetTypeArr = [{"key":"dupont","value":"杜邦图"},{"key":"pie","value":"饼状图"},{"key":"areaMap","value":"区域地图"},{"key":"table","value":"交叉表"},{"key":"datatables","value":"数据表"},{"key":"line","value":"折线/柱状图"},{"key":"contrast","value":"对比图"},{"key":"scatter","value":"散点地图"},{"key":"kpi","value":"指标卡"},{"key":"funnel","value":"漏斗图"},{"key":"san","value":"桑基图"},{"key":"radar","value":"雷达图"},{"key":"map","value":"中国地图"},{"key":"gauge","value":"计量图"},{"key":"wordCloud","value":"标签云"},{"key":"treeMap","value":"矩形树图"},{"key":"heatMapCalendar","value":"热点图（日历）"},{"key":"heatMapTable","value":"热点图（表格）"},{"key":"liquidFill","value":"水球图"},{"key":"chinaMap","value":"中国地图（本地）"},{"key":"chinaMapBmap","value":"中国地图（百度）"},{"key":"relation","value":"关系图"},{"key":"worldMap","value":"世界地图"},{"key":"budget","value":"预算"},{"key":"kpi2","value":"指标卡2"},{"key":"custom","value":"自定义"}];
    //     const widgetTypeArr = _.uniq(_.map(widgetList,function(e){
    //         if(e && e.data && e.data.config && e.data.config.chart_type){
    //              return e.data.config.chart_type;
    //         }
    //    }))
    const typeFilterList = _.map(_.filter(widgetTypeArr, function (e) {
      if (e.value.indexOf(search) > -1) return e;
    }),function(e){
        return e.key;
    })
    const widgetFilterLsit = _.filter(widgetList, function (e) {
      if (e.name.indexOf(search) > -1) return e;
      if (e && e.data && e.data.config && e.data.config.chart_type) {
        const chartType = e.data.config.chart_type;
        if (typeFilterList.indexOf(chartType) > -1) return e;
      }
    })
    return widgetFilterLsit;
  }
}
